var site = site || {};

site.device = {};
site.window = {};
site.owl = {};
site.nav = {};
site.sidebar = {};
site.hero = {};
site.extras = {};
site.extras.initDone = false;
site.qn = {};

site.device.isTouch = function() {
	try {
		document.createEvent("TouchEvent");
		return true;
	} catch (e) {
		return false;
	}
};

site.window.scrollPosition = 0;

// Shorthand to init owlcarousel
site.owl.init = function(id, options) {
	var $owl = $(id);
	var owlLenght = $owl.children().length;

	if (owlLenght > 0) {
		if(owlLenght == 1) {
			options.dots = false;
			options.nav = false;
		}
		$owl.addClass('owl-carousel'); // This is to fix a change made in owl 2.0.0-beta.3
		$owl.owlCarousel(options);
	}
};

site.qn.init = function(codename) {
	$qn = $('#qn');
	if(codename) {
		$qn.find('li.'+codename).addClass('selected');
	}
};

// Extras module
site.extras.init = function() {

	$extras = $('#extras');
	$extras.find('.collapse').click(function(e) {
		e.preventDefault();
		var $this = $(this);
		var $parent = $this.parent('li');
		var $class = $this.attr('class').split(' ')[1];
		var $c = ($class == 'collapseDesc') ? 'openDesc' : 'openMap';
		var $other = ($class == 'collapseDesc') ? 'openMap' : 'openDesc';

		if($parent.hasClass('open'))  {
			if($parent.hasClass($c)) {
				$parent.removeClass('open').toggleClass($c);
			}
			else {
				$parent.removeClass($other).toggleClass($c);
			}
		}
		else {
			$parent.toggleClass('open '+$c);
		}
	});
};
site.extras.upload =  {
	init: function(urls, lang) {
		if(site.extras.initDone === false) {
			site.extras.initDone = true;
			$('ul#images').sortable({
				'opacity': 0.8,
				'update': function (e, ui) {
					var $el = $(this);
					$el.sortable('disable').addClass('disabled');
					$.post(urls.sort, $(this).sortable('serialize'), function (d) {
						if (d.success !== true) {
							moya.message.add(lang.galleryErrorOnSave);
							$el.sortable('cancel');
						}
						$el.removeClass('disabled');
						$el.sortable('enable');
					});
				}
			});
			$('#ffile').uploadify({
				'uploader': '/_moya/js/jquery/uploadify/uploadify.swf',
				'script': urls.script,
				'cancelImg': '/_moya/js/jquery/uploadify/cancel.png',
				'auto': true,
				'multi': true,
				'folder': '/tmp',
				'fileDesc': lang.galleryImageUploadFileDescription,
				'fileExt': '*.jpg;*.jpeg;*.gif;*.png;*.JPG;*.JPEG;*.GIF;*.PNG',
				'buttonText': lang.galleryImageAdd,
				'queueID': 'ffileQueue',
				'onError': function () {
					$('#notice').html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
				},
				'onComplete': function (e, q, f, r) {
					var n = $('#notice');
					switch (r) {
						case 'noAlbum':
							n.html('<div class="message"><div class="error">' + lang.galleryErrorNoAlbumName + '</div></div>');
							break;
						case 'noSave':
							n.html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
							break;
					}
				},
				'onAllComplete': function () {
					window.location.reload();
				}
			});
		}
	}
};

site.extras.timeline = function() {
	site.owl.init('#timelineCategory', {
		dots:false,
		nav:true,
		navText:["<i class=\"fa fa-angle-left\"></i>","<i class=\"fa fa-angle-right\"></i>"],
		autoplay:true,
		margin:20,
		autoplayTimeout:10000,
		smartSpeed:500,
		autoplayHoverPaud:true,
		responsive: {
			0: {
				items:1
			},
			600: {
				items:2
			},
			840: {
				items:3
			},
			992: {
				items:4
			},
			1025: {
				items:5
			}
		}

	});
	var $t = $('#timelineCategory');
	$('#timelineContent').load($t.find('li:first a').attr('href'));
	$t.find('.title,.extrasImage').click(function(e) {
		var $this = $(this);
		var link = $this.find('a').attr('href');
		$('#timelineContent').load(link);
		e.preventDefault();
	});
};


// Init navigation scripts
site.nav.init = function() {
	// Add expand link to mobile menu
	$m = $('#mainmenu');
	$('.children', '#mainmenu').prepend('<a class="expand" href="#"><span class="plus"><i class="fa fa-angle-down"></i></span><span class="minus"><i class="fa fa-angle-up"></i></span></a>');
	$('.selectedLevel1','#mainmenu').addClass('down');

	if($(window).width()>992) {
		$m.find('.level1.children').hover(function() {
			var $this = $(this);

			$m.find('li.open').removeClass('open');/*.find('.mmLevel2').hide();*/
			$this.addClass('open');/*.find('.mmLevel2').show();*/
		},function() {
			var $this = $(this);
			$this.removeClass('open');
		});
	}
	/*
	$(document).mouseup(function (e) {
		var container = $("#mainmenu li.open");
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			container.removeClass('open');
		}
	});
	*/

	site.nav.bind();
	site.nav.appendStuff();
};

// Bind events
site.nav.bind = function() {
	// Mobile menu toggle
	$('#mainmenuToggle').click(function(e) {
		var menuOpen = $('body').hasClass('mainmenu-open');
		if (!menuOpen) {
			site.window.scrollPosition = $(window).scrollTop();
		}
		site.nav.mobileToggle(e, menuOpen, site.window.scrollPosition);
		e.preventDefault();
	});

	// Expand in mobile menu
	$('.expand', '#mainmenu').bind('click', function(e) {
		e.preventDefault();
		var $this = $(this);
		var $ul = $this.siblings('ul');
		var $parent = $(this).parent();

		$ul.toggleClass('hide');
		$this.next().toggleClass('down');
		$parent.toggleClass('down');
		$this.toggleClass('down');
		e.preventDefault();
	});
};

// Toggle mobile menu
site.nav.mobileToggle = function(e, menuOpen, scrollPosition) {
	$('body').toggleClass('mainmenu-open');
	$(this).toggleClass('open');
	if (!menuOpen) {
		$(window).scrollTop(0);
	}
	else {
		$(window).scrollTop(scrollPosition);
	}
};

// Append language to mobilemenu
site.nav.appendLanguage = function() {
	var $divisions = $('.menu', '#divisions');
	var $menu = $('ul.mmLevel1', '#mMenu');

	var $html = $('<li class="ele-language si level1"></li>');
	$divisions.find('li').each(function() {
		var $li = $(this).clone();
		$html.append($li.find('a'));
	});

	$menu.append($html);
};

// Append language to mobilemenu
site.nav.appendStuff = function() {
	var $divisions = $('ul:last', '#qn');
	var $menu = $('.cw', '#mainmenu');

	var $html = $('<div class="lang"></div>');
	$divisions.find('li').each(function() {
		var $li = $(this).clone();
		$html.prepend($li.find('a'));
	});

	$menu.prepend($html);
};

function eqHeight($box1,$box2) {
	$('#main img').load(function() {
		$box1.height(Math.max.apply(Math,$box2.map(function() {return $(this).height()})));
	});
}

function fixDate() {
	$('#main .box_ord_1 li').each(function() {
		$(this).html($(this).html().replace(/&nbsp;/gi,''));
		var $d = $(this).find('.dags');
		$d.html($d.html().replace(/(\S+)\s*$/, '<span class="month">$1</span>'));
	});
}

function isFront() {
	return $('body').is('.frontpage');
}

function smothScroll(){
	$('a[href*=#]:not([href=#])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top
				}, 500);
				return false;
			}
		}
	});
}

// Run all the inits \o/
$(function() {
	site.nav.init();
	if(isFront()) {
		site.owl.init('#banner ul', {
			dots:false,
			nav:true,
			navText:["<i class=\"fa fa-angle-left\"></i>","<i class=\"fa fa-angle-right\"></i>"],
			items: 1,
			autoplay:true,
			autoplayTimeout:10000,
			smartSpeed:500,
			autoplayHoverPaud:true
		});
		fixDate();
	}

	smothScroll();

	// Responsive tables
	if(!$('body').is('.admin')) {
		$('table', '#main').wrap('<div class="table-responsive"></div>');
		$('.table1', '#main').addClass('table');
	}

	// Touch login
	$('.login').bind('click', function(e) {
		e.preventDefault();
		window.location = '/moya';
	});
	setTimeout(function() {
		$('#extras .category li.hasImg').each(function() {
			var $e = $(this).find('.extrasImage');
			var eHeight = $e.height();
			var th = $(this).height();
			if(th > eHeight) {
				$e.height(th);
			}

		});
	},100);

	$employee = $('#employees');
	$employee.find('.branch').click(function(e) {
		$(this).toggleClass('opens').next().toggleClass('opens');
		e.preventDefault();
	});

	// Reorder language menu based on selected
	// Move is to the top
	/*$li = $('.selected', '#divisions');
	$ul = $('.menu', '#divisions');
	$ul.prepend($li);*/
});
